<template>
  <div />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  async mounted() {
    const { token, id } = this.$route.params

    if (!token) {
      this.$router.push({ name: 'error-404' })
    } else {
      try {
        await this.$http.get(`/auth/verify-email/${token}/${id}`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mail has been confirmed!',
            icon: 'BellIcon',
            variant: 'success',
          },
        }, { timeout: false })

        this.$router.push({ name: 'auth-login' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Link is out of date.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        }, { timeout: false })

        this.$router.push({ name: 'auth-login' })
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
